import { AppBar, Box, Button, Tab } from '@mui/material';
import { TabList, TabContext } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { useState, SyntheticEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'PartnerDashboardRoutes';
import ContactUs from 'components/ContactUs';
import { ReactComponent as SuveraLogo } from '../svgs/suvera-logo.svg';

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(location.pathname);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const theme = useTheme();

  return (
    <Box
      component="header"
      sx={{
        borderBottom: '1px solid #E5E5E5',
      }}
    >
      <AppBar
        component="nav"
        position="static"
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          boxShadow: 'none',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'white',
          color: `${theme.palette.text.primary}`,
          justifyContent: 'space-between',
        }}
      >
        <SuveraLogo style={{ marginLeft: 40 }} title="Suvera logo" />
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="Navigation tabs">
            <Tab
              label="Create a referral"
              value={ROUTES.newReferral}
              onClick={() => navigate(ROUTES.newReferral)}
            />

            <Tab
              label="Past referrals"
              value={ROUTES.referrals}
              onClick={() => navigate(ROUTES.referrals)}
            />
          </TabList>
        </TabContext>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ContactUs />
          <Button
            variant="contained"
            color="secondary"
            style={{
              marginRight: 40,
              paddingTop: 10,
              paddingBottom: 10,
            }}
            onClick={() => navigate(ROUTES.logout)}
          >
            Sign out
          </Button>
        </div>
      </AppBar>
    </Box>
  );
}
