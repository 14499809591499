import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { format } from 'date-fns/fp';
import { FC } from 'react';
import { Name, Referral } from './requests';

const columns: GridColDef[] = [
  {
    field: 'patientNhsNumber',
    headerName: 'NHS no.',
    flex: 1,
    hideSortIcons: true,
    resizable: false,
    sortable: false,
  },
  {
    field: 'patientName',
    headerName: 'Patient name',
    flex: 1,
    hideSortIcons: true,
    sortable: false,
  },
  {
    field: 'note',
    headerName: 'Notes',
    flex: 2,
    hideSortIcons: true,
    sortable: false,
  },
  {
    field: 'created',
    headerName: 'Created by',
    flex: 2,
    hideSortIcons: true,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    hideSortIcons: true,
    sortable: false,
  },
];

const formatName = (name: Name) => `${name.first} ${name.last}`;

const formatDate = format('d LLLL yyyy');

const formatReferralStatus = (referral: Referral) => {
  const { type: statusType, datetime, note } = referral.status;
  const formatWithNote = (value: string) => {
    if (note.length > 0) {
      return `${value} - "${note}"`;
    }

    return value;
  };
  switch (statusType) {
    case 'received': {
      return 'Received';
    }
    case 'acknowledged': {
      return `Acknowledged - ${formatDate(datetime)}`;
    }
    case 'rejected': {
      return formatWithNote(`Rejected - ${formatDate(datetime)}`);
    }
    case 'accepted': {
      return formatWithNote(`Accepted - ${formatDate(datetime)}`);
    }
    default: {
      return '';
    }
  }
};

const ReferralsDataGrid: FC<{ referrals: Referral[] }> = ({ referrals }) => {
  const referralRows: GridRowsProp = referrals.map((referral) => ({
    id: referral.id,
    patientNhsNumber: referral.patient.nhsNumber,
    patientName: formatName(referral.patient.name),
    note: referral.note,
    created: `${formatName(referral.partner.name)} - ${formatDate(
      referral.createdAt,
    )}`,
    status: formatReferralStatus(referral),
    expanded: true,
  }));

  return (
    <Box sx={{ height: '550px' }}>
      <DataGrid
        rows={referralRows}
        columns={columns}
        columnBuffer={columns.length}
        hideFooter
        disableColumnSelector
        disableColumnMenu
        getRowHeight={(params) => {
          return params.model.expanded === true ? 'auto' : undefined;
        }}
      />
    </Box>
  );
};

export default ReferralsDataGrid;
