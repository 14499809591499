import { useNavigate } from 'react-router-dom';
import { FC, ReactElement, useEffect } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { captureException } from '@sentry/react';
import { Avo } from './analytics';

const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const SCOPE = process.env.REACT_APP_AUTH0_SCOPE;
const REDIRECT_URI = `${window.location.origin}/auth0`;

export const Auth0CallbackComponent = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated === true) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);
  return null;
};

export const RequireAuth = ({ children }: { children: ReactElement }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated === false && !isLoading) {
      loginWithRedirect().catch((error) => {
        captureException(error);
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (!isAuthenticated) return null;
  return children;
};

export const Logout = () => {
  const { logout } = useAuth0();
  const handleLogout = () => {
    Avo.userSignedOut();
    logout({
      returnTo: window.location.origin,
    });
  };
  useEffect(() => {
    handleLogout();
  });
  return null;
};

interface SuveraAuth0ProviderProps {
  children: ReactElement;
}

export const SuveraAuth0Provider: FC<SuveraAuth0ProviderProps> = ({
  children,
}) => (
  <Auth0Provider
    domain={DOMAIN!}
    clientId={CLIENT_ID!}
    audience={AUDIENCE}
    redirectUri={REDIRECT_URI}
    scope={SCOPE}
  >
    {children}
  </Auth0Provider>
);
