import { Link, Typography } from '@mui/material';
import { SUVERA_CONTACT_US_NUMBER } from 'components/constants';
import { SuveraPalette } from 'theme/palette';
import { ReactComponent as TelephoneIcon } from './telephone-icon.svg';

export default function ContactUs() {
  return (
    <div
      data-testid="contact-us"
      style={{
        marginLeft: 10,
        marginRight: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <TelephoneIcon style={{ marginRight: 5 }} title="telephone handle" />
      <Typography
        style={{
          marginRight: 10,
        }}
      >
        Call us:
      </Typography>
      <Link
        aria-label="call us phone number"
        href={`tel:${SUVERA_CONTACT_US_NUMBER}`}
        style={{
          color: `${SuveraPalette.blue.medium}`,
          textDecorationColor: `${SuveraPalette.blue.medium}`,
        }}
      >
        {SUVERA_CONTACT_US_NUMBER}
      </Link>
    </div>
  );
}
