import { Routes, Route, Navigate } from 'react-router-dom';
import { SentryTestComponent } from 'infrastructure/sentry';
import { RequirePartner } from 'components/RequirePartner';
import CreateReferral from 'components/CreateReferral';
import PartnerReferralLog from './PartnerReferralLog';
import {
  RequireAuth,
  Auth0CallbackComponent,
  Logout,
} from './infrastructure/auth';
import Page from './components/Page';

export const ROUTES = {
  newReferral: '/create-a-new-referral',
  referrals: '/referrals',
  logout: '/logout',
};

const RedirectToReferralsPage = () => <Navigate to={ROUTES.referrals} />;

const PartnerDashboardRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth>
            <RedirectToReferralsPage />
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.newReferral}
        element={
          <RequireAuth>
            <Page>
              <CreateReferral />
            </Page>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.referrals}
        element={
          <RequireAuth>
            <Page>
              <RequirePartner>
                <PartnerReferralLog />
              </RequirePartner>
            </Page>
          </RequireAuth>
        }
      />
      <Route path="/auth0" element={<Auth0CallbackComponent />} />
      <Route path={ROUTES.logout} element={<Logout />} />
      <Route path="/__error" element={<SentryTestComponent />} />
      <Route path="*" element={<p>404</p>} />
    </Routes>
  );
};

export default PartnerDashboardRoutes;
