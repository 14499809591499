import Box from '@mui/material/Box';
import type { FC, ReactNode } from 'react';
import Header from '../Header';

const Page: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    display="flex"
    justifyContent="stretch"
    flexDirection="column"
    minHeight="100vh"
  >
    <Header />
    {children}
  </Box>
);

export default Page;
