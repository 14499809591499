import { FormEvent } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Typography,
  Autocomplete,
} from '@mui/material';

const handleSubmit = (e: FormEvent) => {
  e.preventDefault();
  //   TODO add validation/error states
  //   TODO add create referral request here
};

export default function CreateReferral() {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '95px auto',
        padding: '24px 16px',
        width: 506,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: 20,
          }}
        >
          Create a new referral
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          Fill out the details below
        </Typography>
        <FormControl fullWidth>
          <FormLabel
            style={{ marginTop: 28, marginBottom: 8, color: '#42495E' }}
          >
            NHS number
          </FormLabel>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={['11111111', '11111111', '11111111']}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel
            style={{ marginTop: 28, marginBottom: 8, color: '#42495E' }}
          >
            Anything you want our team to know?
          </FormLabel>
          <TextField id="free-text" variant="outlined" multiline rows={4} />
        </FormControl>
        <FormControl fullWidth>
          <Button
            variant="contained"
            type="submit"
            style={{ height: 50, marginTop: 29 }}
          >
            Next
          </Button>
        </FormControl>
      </form>
    </Box>
  );
}
