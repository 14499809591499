import { useQuery } from 'react-query';
import { z } from 'zod';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { parseISO } from 'date-fns/fp';
import camelcaseKeys from 'camelcase-keys';
import axios, { AxiosError } from 'axios';
import { captureException } from '@sentry/react';

const nameSchema = z.object({ first: z.string(), last: z.string() });

export type Name = z.infer<typeof nameSchema>;

const patientSchema = z
  .object({
    _id: z.string(),
    name: nameSchema,
    nhs_number: z.string(),
  })
  .transform((patient) => camelcaseKeys(patient));

const partnerSchema = z.object({
  _id: z.string(),
  name: nameSchema,
});

const referralSchema = z
  .object({
    _id: z.string(),
    patient: patientSchema,
    note: z.string(),
    partner: partnerSchema,
    created_at: z.string().transform((dateString) => parseISO(dateString)),
    status: z.object({
      type: z.enum(['acknowledged', 'received', 'accepted', 'rejected']),
      datetime: z.string().transform((dateString) => parseISO(dateString)),
      note: z.string(),
    }),
  })
  .transform((referral) => camelcaseKeys(referral));

export type Referral = z.infer<typeof referralSchema>;

const getReferralsResponseSchema = z.object({
  result: z.array(referralSchema),
});

type GetReferralsResponse = z.infer<typeof getReferralsResponseSchema>;

export const useGetReferrals = () => {
  const suveraAxiosInstance = useAuthenticatedAxios();
  return useQuery({
    queryKey: ['referrals'],
    queryFn: async (): Promise<Referral[]> => {
      return suveraAxiosInstance
        .get<GetReferralsResponse>('/referrals')
        .then(
          (response) => getReferralsResponseSchema.parse(response.data).result,
        );
    },
    retry: false,
    onError: (error: Error | AxiosError | unknown) => {
      if (axios.isAxiosError(error) && error.response) {
        return;
      }
      captureException(error);
    },
  });
};
