import * as Inspector from 'avo-inspector';
import { AnalyticsBrowser } from '@segment/analytics-next';
import Avo from './Avo';

let analytics: AnalyticsBrowser;

const customSegmentDestination = {
  make() {
    analytics = AnalyticsBrowser.load({
      writeKey: process.env.REACT_APP_SEGMENT_API_KEY || '',
    });
  },
  logEvent(eventName: string, eventProperties: object) {
    return analytics.track(eventName, eventProperties);
  },
};

const getAvoInspectorEnv = () => {
  switch (process.env.REACT_APP_SST_STAGE) {
    case 'production': {
      return Inspector.AvoInspectorEnv.Prod;
    }
    default: {
      return Inspector.AvoInspectorEnv.Dev;
    }
  }
};

const getAvoCodegenEnv = () => {
  switch (process.env.REACT_APP_SST_STAGE) {
    case 'production': {
      return Avo.AvoEnv.Prod;
    }
    default: {
      return Avo.AvoEnv.Dev;
    }
  }
};

const isAnalyticsEnabled = () => {
  return process.env.REACT_APP_ENABLE_ANALYTICS === 'true' || false;
};

export const initialiseAnalytics = () => {
  const inspector = new Inspector.AvoInspector({
    apiKey: Avo.avoInspectorApiKey,
    env: getAvoInspectorEnv(),
    version: '1.0.0',
    appName: 'Partner Dashboard',
  });

  Avo.initAvo(
    {
      env: getAvoCodegenEnv(),
      inspector,
      noop: !isAnalyticsEnabled(),
      webDebugger: false,
    },
    {},
    customSegmentDestination,
  );
};

export { default as Avo } from './Avo';
