import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { GenericError } from 'components/Errors';
import { useGetReferrals } from './requests';
import EmptyState from './EmptyState';
import ReferralsDataGrid from './ReferralsDataGrid';

const PartnerReferralsLog = () => {
  const query = useGetReferrals();

  if (query.isLoading || query.isIdle) return null;

  if (query.isError) {
    return <GenericError />;
  }

  if (query.isSuccess && query.data.length === 0) {
    return <EmptyState />;
  }

  return (
    <Stack pt="40px" px="48px" rowGap="20px" flexGrow="1">
      <Typography variant="h2" component="h1">
        Past referrals
      </Typography>
      <ReferralsDataGrid referrals={query.data} />
    </Stack>
  );
};

export default PartnerReferralsLog;
