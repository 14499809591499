import { Stack, Typography } from '@mui/material';
import { SUVERA_CONTACT_US_NUMBER } from 'components/constants';
import { GenericError } from 'components/Errors';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useGetIsSuveraPartner } from './requests';

export const RequirePartner: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const partnerQuery = useGetIsSuveraPartner();

  if (partnerQuery.isError) {
    return <GenericError />;
  }

  if (
    partnerQuery.isSuccess &&
    partnerQuery.data.isAuthorisedPartner === false
  ) {
    return (
      <Stack height="100vh" justifyContent="center" textAlign="center">
        <Typography variant="h2">
          We don&apos;t recognise your email address
        </Typography>
        <Typography>
          If you are a Suvera partner and would like access to our dashboard,
          please call us on {SUVERA_CONTACT_US_NUMBER}
        </Typography>
        <Link to="/logout">Sign out</Link>
      </Stack>
    );
  }

  if (
    partnerQuery.isSuccess &&
    partnerQuery.data.isAuthorisedPartner === true
  ) {
    return children;
  }

  return null;
};
