import { PaletteColorOptions } from '@mui/material';

export const primaryPalette: PaletteColorOptions = { main: '#3D90FF' };

export const secondaryPalette: PaletteColorOptions = {
  main: '#68F8A6',
  contrastText: '#0F1B38',
};

export const SuveraPalette = {
  blue: {
    // TODO incorporate into mui theme
    medium: '#2442A4',
  },
};
