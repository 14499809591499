import { captureException } from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { useQuery } from 'react-query';
import { z } from 'zod';

const nameSchema = z.object({ first: z.string(), last: z.string() });

export type Name = z.infer<typeof nameSchema>;

const partnerSchema = z.object({
  _id: z.string(),
  name: nameSchema,
});

const getPartnerResponseSchema = z.object({
  result: partnerSchema,
});

type GetPartnerResponse = z.infer<typeof getPartnerResponseSchema>;

export const useGetIsSuveraPartner = () => {
  const suveraAxiosInstance = useAuthenticatedAxios();
  return useQuery({
    queryKey: ['partnerMe'],
    queryFn: async (): Promise<{ isAuthorisedPartner: boolean }> =>
      suveraAxiosInstance
        .get<GetPartnerResponse>('/partners/me')
        .then((response) => {
          getPartnerResponseSchema.parse(response.data);
          return { isAuthorisedPartner: true };
        })
        .catch((error) => {
          if (axios.isAxiosError(error) && error.response?.status === 403) {
            return { isAuthorisedPartner: false };
          }
          throw error;
        }),
    retry: false,
    onError: (error: Error | AxiosError | unknown) => {
      if (axios.isAxiosError(error) && error.response) {
        return;
      }
      captureException(error);
      throw error;
    },
  });
};
