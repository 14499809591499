import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ROUTES } from 'PartnerDashboardRoutes';
import { ReactComponent as Reading } from './reading.svg';

const EmptyState: FC = () => {
  const navigate = useNavigate();
  return (
    <Stack pt="100px" pb="100px" alignItems="center" rowGap="32px" flexGrow="1">
      <Reading />
      <Typography component="h1" variant="h2">
        No referrals have been created
      </Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => navigate(ROUTES.newReferral)}
      >
        Create a new referral
      </Button>
    </Stack>
  );
};

export default EmptyState;
