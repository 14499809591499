import { FC } from 'react';
import PartnerDashboardRoutes from 'PartnerDashboardRoutes';
import { SuveraApiQueryProvider } from 'infrastructure/ReactQuery';
import { ErrorBoundary } from '@sentry/react';
import { ErrorBoundaryFallbackPage } from 'components/Errors';
import { ThemeProvider } from '@mui/material';
import theme from './theme';

interface AppProps {
  initialToken?: string;
}

const App: FC<AppProps> = () => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary fallback={<ErrorBoundaryFallbackPage />}>
        <SuveraApiQueryProvider>
          <PartnerDashboardRoutes />
        </SuveraApiQueryProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
