import type { DataGridComponents } from '@mui/x-data-grid/themeAugmentation';

/* colours not currently from ./palette.ts - need to clarify whether these are to be standardised */
const borderDefault = '1px solid #DEDEDE';

export const muiDataGridOverrides: DataGridComponents['MuiDataGrid'] = {
  styleOverrides: {
    root: {
      border: 0,
    },
    columnHeader: {
      background: '#FAFAFA',
      borderBottom: borderDefault,
      textAlign: 'left',
      padding: '20px 0 20px 20px',
    },
    columnHeaders: {
      border: 0,
    },
    columnHeaderTitle: {
      fontWeight: '500',
    },
    cell: {
      borderBottom: borderDefault,
      height: 'none',
      padding: '20px 0 20px 20px',
    },

    iconSeparator: {
      display: 'none',
    },
  },
};
