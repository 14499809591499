import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance } from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;
let axiosInstance: AxiosInstance | null = null;

export const useAuthenticatedAxios = () => {
  const { getAccessTokenSilently } = useAuth0();

  if (axiosInstance !== null) return axiosInstance;

  axiosInstance = axios.create({
    baseURL: API_ENDPOINT,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    const accessToken = await getAccessTokenSilently();
    const headers = (config.headers as {}) || {};
    return {
      ...config,
      headers: { ...headers, Authorization: `Bearer ${accessToken}` },
    };
  });

  return axiosInstance;
};
