import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const initialiseSentry = () => {
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: process.env.REACT_APP_SST_STAGE || process.env.NODE_ENV,
      tracesSampleRate: 0,
      beforeBreadcrumb(breadcrumb, hint?) {
        if (breadcrumb.category === 'fetch') {
          const rawFetchResponse = hint?.response as Response;
          const modifiedBreadcrumb = {
            ...breadcrumb,
            data: {
              ...breadcrumb.data,
              'AWS API Gateway Request ID':
                rawFetchResponse.headers.get('apigw-requestid'),
              'AWS XRAY Request ID':
                rawFetchResponse.headers.get('xray-requestid'),
              'AWS Lambda Request ID':
                rawFetchResponse.headers.get('lambda-requestid'),
              'AWS Lambda Function':
                rawFetchResponse.headers.get('lambda-function'),
              'AWS Lambda Log Stream':
                rawFetchResponse.headers.get('lambda-logstream'),
            },
          };
          return modifiedBreadcrumb;
        }
        return breadcrumb;
      },
    });
  }
};

export const SentryTestComponent = () => {
  return <p>{process.env.THIS_DOES_NOT_EXIST!.toLowerCase()}</p>;
};
