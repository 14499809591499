import { createTheme } from '@mui/material';
import { muiDataGridOverrides } from './muiDataGrid';
import { primaryPalette, secondaryPalette } from './palette';

const theme = createTheme({
  typography: {
    fontFamily: 'Readex Pro, sans-serif',
    body1: { fontSize: '16px' },
    h2: { fontSize: '20px', fontWeight: '700' },
    button: {
      textTransform: 'none',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: '600',
    },
  },
  palette: {
    primary: primaryPalette,
    secondary: secondaryPalette,
    text: {
      primary: '#0F1B38',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        contained: { padding: '20px' },
      },
    },
    MuiDataGrid: muiDataGridOverrides,
  },
});

export default theme;
